import {
  FETCHING_CART,
  CHANGE_QTY,
  FETCHING_CART_FAILURE,
  RECEIVED_CART,
  RECEIVED_SUGGESTIONS,
  SWAPPING_ITEM,
  ITEM_SWAPPED,
  ADDED_TO_CART,
  ADD_TO_CART_FAILURE,
  ADDING_PRODUCT,
  UPDATING_QTY,
  UPDATING_QTY_FAILURE,
  READDING_LINE_ITEM,
  READDING_LINE_ITEM_FAILURE,
  REMOVING_LINE_ITEM,
  SAVING_LINE_ITEM,
  SAVING_LINE_ITEM_FAILURE,
  SAVED_LINE_ITEM,
  REMOVE_LINE_ITEM_FAILURE,
  REMOVED_LINE_ITEM,
  SET_CARTS_TOTAL,
  LOADING_ITEMS_FAILURE,
  LOADING_ITEMS,
  RESET_LOADING_ITEMS,
  LOADED_ITEMS,
  DISABLE_ALL_APPLY_CONTROL_BTN,
  ENABLE_APPLY_CONTROL_BTN,
  REFRESH_LIVE_UPDATES
} from '../actions/cart'

const initialState = {
  loading: false,
  adding: false,
  animateHeaderSummary: false,
  cart: [],
  cartLineItemsUpdating: {},
  cartLineItems: {},
  hasMorePages: {},
  itemsLoading: {},
  cartLineItemsTotal: {},
  cartLineItemsQty: {},
  cartLineItemsSoftDeleted: {},
  cartsTotal: 0,
  cartSuggestions: {},
  reloadSuggestions: {},
  controlBtnMap: {},
  totalItemsCount: 0,
  updatingVariantId: 0,
  qtyByVariantId: {},
  qty: 1,
  disableAllApplyControlBtn: false,
  externalWebServiceAvailable: false
}

const updateCart = (state, action, status) => {
  let qtyDiff = parseInt(action.updatedQty) - parseInt(action.oldQty)
  qtyDiff = status === 'failed' ? -qtyDiff : qtyDiff
  const totalItem = state.totalItemsCount + qtyDiff
  const cartTotal =
    parseFloat(state.cartsTotal) +
    parseFloat(parseFloat(action.lineItem.price) * qtyDiff)
  let orderTotal = state.cartLineItemsTotal[action.cartId]
  const lineItemsTotal =
    parseFloat(orderTotal.total) + parseFloat(action.lineItem.price) * qtyDiff
  const lineItemsCount = orderTotal.totalItems + qtyDiff

  return {
    totalItem: totalItem,
    cartTotal: parseFloat(cartTotal.toFixed(2)),
    lineItemsTotal: parseFloat(lineItemsTotal.toFixed(2)),
    lineItemsCount: lineItemsCount
  }
}

const fetchItemsLoadingState = (state, cartId) => {
  let itemsLoading = {}
  itemsLoading[cartId] = { loading: state }
  return itemsLoading
}

const updateOldCartAfterSwap = (state, action) => {
  let cartItems = state.cartLineItems[action.oldCartId]
  let lineItem = action.payload.original_line_item
  cartItems = cartItems.filter(function(item) {
    return item.id != action.payload.original_line_item.id
  })
  let cartTotalObj = state.cartLineItemsTotal[action.oldCartId]
  const oldCartTotal =
    cartTotalObj.total - parseFloat(lineItem.price * action.quantity)
  const totalItems = cartTotalObj.totalItems - action.quantity
  return {
    items: cartItems,
    oldCartTotal: oldCartTotal,
    totalItems: totalItems
  }
}

const addProductToExistingCart = (state, action, items) => {
  let lineItem = action.payload.line_item
  let lineItems = items
  let presentItem = items.find(item => {
    return item.id === lineItem.id
  })
  if (presentItem) {
    state.cartLineItemsQty[lineItem.id] = presentItem.quantity + action.quantity
    state.cartLineItemsSoftDeleted[lineItem.id] = false
    state.totalItemsCount = state.totalItemsCount - 1
  } else {
    lineItems = [lineItem, ...items]
  }
  state.cartsTotal =
    state.cartsTotal -
    parseFloat(action.payload.original_line_item.price) +
    parseFloat(lineItem.price)
  return lineItems
}

const fetchNewLineItem = (state, action) => {
  let cartItems = state.cartLineItems[action.cartId]
  let currentCartTotalObj = state.cartLineItemsTotal[action.cartId]
  const newItems = [action.payload.line_item, ...cartItems]
  const newTotalObj = {
    total:
      currentCartTotalObj.total +
      parseFloat(action.payload.line_item.price * action.newItemQty),
    totalItems: currentCartTotalObj.totalItems + action.newItemQty
  }
  return {
    newItems: newItems,
    newTotalObj: newTotalObj
  }
}

export default (state = initialState, action) => {
  switch (action.type) {
    case CHANGE_QTY:
      state.qtyByVariantId[action.variantId] = action.qty
      return {
        ...state,
        qtyByVariantId: state.qtyByVariantId
      }

    case SET_CARTS_TOTAL:
      return {
        ...state,
        cartsTotal: action.cartsTotal,
        totalItemsCount: action.totalItemsCount
      }

    case ADDING_PRODUCT:
      return {
        ...state,
        updatingVariantId: action.variantId,
        animateHeaderSummary: true,
        adding: true
      }

    case ADDED_TO_CART:
      state.qtyByVariantId[action.variantId] = 1 // reset to 1
      if (action.cartId) {
        const updateCart = fetchNewLineItem(state, action)
        state.cartLineItems[action.cartId] = updateCart.newItems
        state.cartLineItemsTotal[action.cartId] = updateCart.newTotalObj
      }
      const updateCounterBy = action.payload.already_added ? 0 : 1
      state.reloadSuggestions[action.cartId] = true
      return {
        ...state,
        cartsTotal:
          state.cartsTotal + parseFloat(action.payload.line_item_price),
        totalItemsCount: state.totalItemsCount + updateCounterBy,
        updatingVariantId: 0,
        qtyByVariantId: state.qtyByVariantId,
        cartLineItems: state.cartLineItems,
        cartLineItemsTotal: state.cartLineItemsTotal,
        animateHeaderSummary: false,
        adding: false,
        reloadSuggestions: state.reloadSuggestions
      }

    case ADD_TO_CART_FAILURE:
      state.qtyByVariantId[action.variantId] = 1 // reset to 1
      return {
        ...state,
        updatingVariantId: 0,
        qtyByVariantId: state.qtyByVariantId,
        adding: false
      }

    case DISABLE_ALL_APPLY_CONTROL_BTN:
      state.controlBtnMap[action.supplierId] = {
        disableAllApplyControlBtn: true
      }
      return {
        ...state,
        disableAllApplyControlBtn: true,
        controlBtnMap: state.controlBtnMap
      }

    case UPDATING_QTY:
      let updatedState = updateCart(state, action, 'updating')
      state.cartLineItemsTotal[action.cartId] = {
        total: updatedState.lineItemsTotal,
        totalItems: updatedState.lineItemsCount
      }
      state.cartLineItemsQty[action.lineItem.id] = action.updatedQty
      return {
        ...state,
        cartLineItemsQty: state.cartLineItemsQty,
        totalItemsCount: state.totalItemsCount,
        cartsTotal: updatedState.cartTotal,
        cartLineItemsTotal: state.cartLineItemsTotal
      }

    case UPDATING_QTY_FAILURE:
      let updateFailureState = updateCart(state, action, 'failed')
      state.cartLineItemsTotal[action.cartId] = {
        total: updateFailureState.lineItemsTotal,
        totalItems: updateFailureState.lineItemsCount
      }
      state.cartLineItemsQty[action.lineItem.id] = action.oldQty

      return {
        ...state,
        totalItemsCount: state.totalItemsCount,
        cartsTotal: updateFailureState.cartTotal,
        cartLineItemsQty: state.cartLineItemsQty,
        cartLineItemsTotal: state.cartLineItemsTotal
      }

    case READDING_LINE_ITEM:
      let readdedState = updateCart(state, action, 'updating')
      state.cartLineItemsTotal[action.cartId] = {
        total: readdedState.lineItemsTotal,
        totalItems: readdedState.lineItemsCount
      }
      state.cartLineItemsQty[action.lineItem.id] = action.updatedQty
      state.cartLineItemsSoftDeleted[action.lineItem.id] = false

      return {
        ...state,
        cartLineItemsQty: state.cartLineItemsQty,
        cartLineItemsSoftDeleted: state.cartLineItemsSoftDeleted,
        totalItemsCount: state.totalItemsCount + 1,
        cartsTotal: readdedState.cartTotal,
        cartLineItemsTotal: state.cartLineItemsTotal
      }

    case READDING_LINE_ITEM_FAILURE:
      let readdFailureState = updateCart(state, action, 'failed')
      state.cartLineItemsTotal[action.cartId] = {
        total: readdFailureState.lineItemsTotal,
        totalItems: readdFailureState.lineItemsCount
      }
      state.cartLineItemsQty[action.lineItem.id] = action.oldQty
      state.cartLineItemsSoftDeleted[action.lineItem.id] = true

      return {
        ...state,
        totalItemsCount: state.totalItemsCount - 1,
        cartsTotal: readdFailureState.cartTotal,
        cartLineItemsQty: state.cartLineItemsQty,
        cartLineItemsSoftDeleted: state.cartLineItemsSoftDeleted,
        cartLineItemsTotal: state.cartLineItemsTotal
      }

    case REMOVING_LINE_ITEM:
      let cartRecords = state.cartLineItems[action.cartId]
      let cartLineItemsObj = state.cartLineItemsTotal[action.cartId]
      cartRecords = cartRecords.filter(item => {
        return item.id != action.lineItem.id
      })
      state.cartLineItems[action.cartId] = cartRecords
      state.cartLineItemsTotal[action.cartId] = {
        total:
          cartLineItemsObj.total -
          parseFloat(action.lineItem.price * action.updatedQty),
        totalItems: cartLineItemsObj.totalItems - action.updatedQty
      }
      state.reloadSuggestions[action.cartId] = true
      return {
        ...state,
        cartsTotal:
          parseFloat(state.cartsTotal) -
          parseFloat(action.lineItem.price * action.updatedQty),
        totalItemsCount: state.totalItemsCount - 1,
        cart: state.cart,
        cartLineItems: state.cartLineItems,
        cartLineItemsTotal: state.cartLineItemsTotal,
        reloadSuggestions: state.reloadSuggestions
      }

    case REMOVE_LINE_ITEM_FAILURE:
      let presentItems = state.cartLineItems[action.cartId]
      let currentTotalObj = state.cartLineItemsTotal[action.cartId]
      const newArr = [action.lineItem, ...presentItems]
      state.cartLineItems[action.cartId] = newArr
      state.cartLineItemsTotal[action.cartId] = {
        total:
          currentTotalObj.total +
          parseFloat(action.lineItem.price * action.updatedQty),
        totalItems: currentTotalObj.totalItems + action.updatedQty
      }

      return {
        ...state,
        cartsTotal:
          state.cartsTotal +
          parseFloat(action.lineItem.price * action.updatedQty),
        totalItemsCount: state.totalItemsCount + 1,
        cartLineItems: state.cartLineItems,
        cartLineItemsTotal: state.cartLineItemsTotal
      }

    case REMOVED_LINE_ITEM:
      state.cartLineItemsTotal[action.cartId] = {
        total: parseFloat(action.itemTotal),
        totalItems: action.itemCount
      }

      return {
        ...state,
        cartLineItemsTotal: state.cartLineItemsTotal
      }

    case FETCHING_CART:
      return {
        ...state,
        loading: true
      }

    case FETCHING_CART_FAILURE:
      return {
        ...state,
        loading: false
      }

    case ENABLE_APPLY_CONTROL_BTN:
      state.controlBtnMap[action.supplierId] = {
        disableAllApplyControlBtn: false
      }
      return {
        ...state,
        controlBtnMap: state.controlBtnMap
      }

    case RECEIVED_CART:
      let itemsTotal = {}
      let controlBtnMap = {}
      if(action.singleCart) {
        controlBtnMap[action.payload.supplier_id] = {
          disableAllApplyControlBtn: false
        }
        itemsTotal[action.payload.id] = {
          total: parseFloat(action.payload.item_total),
          totalItems: action.payload.item_count
        }
      } else {
        action.payload.forEach(element => {
          controlBtnMap[element.supplier_id] = {
            disableAllApplyControlBtn: false
          }
          itemsTotal[element.id] = {
            total: parseFloat(element.item_total),
            totalItems: element.item_count
          }
        })
      }

      return {
        ...state,
        loading: false,
        cart: action.payload,
        controlBtnMap: controlBtnMap,
        cartLineItemsTotal: itemsTotal
      }

    case LOADING_ITEMS:
      return {
        ...state,
        itemsLoading: {
          ...state.itemsLoading,
          ...fetchItemsLoadingState(true, action.cartId)
        }
      }
    case RESET_LOADING_ITEMS:
      return {
        ...state,
        itemsLoading: {}
      }

    case LOADING_ITEMS_FAILURE:
      return {
        ...state,
        itemsLoading: {
          ...state.itemsLoading,
          ...fetchItemsLoadingState(false, action.cartId)
        }
      }

    case LOADED_ITEMS:
      let items = {}
      let cartMorePages = {}
      let oldItems = state.cartLineItems[action.cartId]
      oldItems =
        oldItems && Object.keys(oldItems).length > 0 && !action.reset
          ? oldItems
          : []
      items[action.cartId] = oldItems.concat(action.payload)
      cartMorePages[action.cartId] = action.hasMore
      let showLoading = action.showLoading
        ? {
            ...state.itemsLoading,
            ...fetchItemsLoadingState(false, action.cartId)
          }
        : state.itemsLoading
      return {
        ...state,
        cartLineItems: { ...state.cartLineItems, ...items },
        hasMorePages: { ...state.hasMorePages, ...cartMorePages },
        itemsLoading: showLoading
      }

    case RECEIVED_SUGGESTIONS:
      state.cartSuggestions[action.cartId] = action.payload
      state.reloadSuggestions[action.cartId] = false
      return {
        ...state,
        cartSuggestions: state.cartSuggestions,
        reloadSuggestions: state.reloadSuggestions
      }

    case SWAPPING_ITEM:
      let suggestions = state.cartSuggestions[action.cartId]
      suggestions = suggestions.filter(function(item) {
        return item.line_item.id != action.suggestion.line_item.id
      })
      state.cartSuggestions[action.cartId] = suggestions
      return {
        ...state,
        cartSuggestions: state.cartSuggestions
      }

    case ITEM_SWAPPED:
      let lineItem = action.payload.line_item
      const newCartId = action.suggestion.new_cart_id
      let newCartTotalObj = state.cartLineItemsTotal[newCartId]
      const newCartItems = state.cartLineItems[newCartId]
      const updatedOldCartItems = updateOldCartAfterSwap(state, action)
      state.cartLineItems[action.oldCartId] = updatedOldCartItems.items
      state.cartLineItemsTotal[action.oldCartId] = {
        total: updatedOldCartItems.oldCartTotal,
        totalItems: updatedOldCartItems.totalItems
      }
      state.cartLineItems[newCartId] = addProductToExistingCart(
        state,
        action,
        newCartItems
      )
      state.cartLineItemsTotal[newCartId] = {
        total:
          newCartTotalObj.total + parseFloat(lineItem.price * action.quantity),
        totalItems: newCartTotalObj.totalItems + action.quantity
      }
      return {
        ...state,
        cartsTotal: state.cartsTotal,
        totalItemsCount: state.totalItemsCount,
        cartLineItems: state.cartLineItems,
        cartLineItemsTotal: state.cartLineItemsTotal,
        cartLineItemsQty: state.cartLineItemsQty
      }

    case REFRESH_LIVE_UPDATES:
      state.cartLineItems[action.cartId] = action.payload.items
      state.cartLineItemsTotal[action.cartId] = {
        total: parseFloat(action.payload.item_total),
        totalItems: action.payload.item_count
      }
      action.payload.items.forEach(element => {
        state.cartLineItemsQty[element.id] = element.quantity
        state.cartLineItemsSoftDeleted[element.id] = element.soft_deleted
      })

      return {
        ...state,
        cartLineItems: state.cartLineItems,
        cartLineItemsTotal: state.cartLineItemsTotal,
        cartLineItemsQty: state.cartLineItemsQty,
        cartLineItemsSoftDeleted: state.cartLineItemsSoftDeleted
      }

    default:
      return state
  }
}
