import { api } from '../axios'
import { apiUrls } from '../helpers/apiHelper'
import plausible, { events } from '../helpers/plausibleHelper'

export const FETCHING_CART = 'cart/FETCHING_CART'
export const FETCHING_CART_FAILURE = 'cart/FETCHING_CART_FAILURE'
export const RECEIVED_CART = 'cart/RECEIVED_CART'
export const RECEIVED_SUGGESTIONS = 'cart/RECEIVED_SUGGESTIONS'
export const SWAPPING_ITEM = 'cart/SWAPPING_ITEM'
export const ITEM_SWAPPED = 'cart/ITEM_SWAPPED'
export const ADDED_TO_CART = 'cart/ADDED_TO_CART'
export const ADD_TO_CART_FAILURE = 'cart/ADD_TO_CART_FAILURE'
export const ADDING_PRODUCT = 'cart/DECREMENT'
export const UPDATING_QTY = 'cart/UPDATING_QTY'
export const UPDATING_QTY_FAILURE = 'cart/UPDATING_QTY_FAILURE'

export const READDING_LINE_ITEM = 'cart/READDING_LINE_ITEM'
export const READDING_LINE_ITEM_FAILURE = 'cart/READDING_LINE_ITEM_FAILURE'

export const REMOVING_LINE_ITEM = 'cart/REMOVING_LINE_ITEM'
export const REMOVE_LINE_ITEM_FAILURE = 'cart/REMOVE_LINE_ITEM_FAILURE'
export const REMOVED_LINE_ITEM = 'cart/REMOVED_LINE_ITEM'

export const SET_CARTS_TOTAL = 'cart/SET_COUNTER'
export const CHANGE_QTY = 'cart/CHANGE_QTY'
export const LOADING_ITEMS = 'cart/LOADING_ITEMS'
export const RESET_LOADING_ITEMS = 'cart/RESET_LOADING_ITEMS'
export const LOADING_ITEMS_FAILURE = 'cart/LOADING_ITEMS_FAILURE'
export const LOADED_ITEMS = 'cart/LOADED_ITEMS'

export const UPDATING_LINE_ITEM_REQUIRED = 'cart/UPDATING_LINE_ITEM_REQUIRED'
export const UPDATING_LINE_ITEM_REQUIRED_FAILURE =
  'cart/UPDATING_LINE_ITEM_REQUIRED_FAILURE'

export const DISABLE_ALL_APPLY_CONTROL_BTN =
  'cart/DISABLE_ALL_APPLY_CONTROL_BTN'
export const ENABLE_APPLY_CONTROL_BTN = 'cart/ENABLE_APPLY_CONTROL_BTN'

export const REFRESH_LIVE_UPDATES = 'cart/REFRESH_LIVE_UPDATES'

export const loadSuggestions = (supplierId, cartId, callback) => {
  return (dispatch) => {
    return api
      .get(apiUrls.cart.getSugessions(supplierId))
      .then((response) => {
        dispatch({
          type: RECEIVED_SUGGESTIONS,
          cartId: cartId,
          payload: response.data
        })
        callback()
      })
      .catch((e) => {
        console.error(e)
        callback()
      })
  }
}

export const disableAllApplyControlBtn = (supplierId) => {
  return (dispatch) => {
    dispatch({
      type: DISABLE_ALL_APPLY_CONTROL_BTN,
      supplierId: supplierId
    })
  }
}

export const enableApplyControlBtn = (supplierId) => {
  return (dispatch) => {
    dispatch({
      type: ENABLE_APPLY_CONTROL_BTN,
      supplierId: supplierId
    })
  }
}

export const swapCart = (
  suggestion,
  cartId,
  supplierId,
  quantity,
  callback
) => {
  return (dispatch) => {
    dispatch({
      type: SWAPPING_ITEM,
      suggestion,
      cartId
    })

    return api
      .post(apiUrls.cart.swapLineItem(supplierId), {
        type: suggestion.type,
        product_id: suggestion.supplier_product.id,
        line_item_id: suggestion.line_item.id,
        new_cart_id: suggestion.new_cart_id,
        quantity
      })
      .then((response) => {
        dispatch({
          type: ITEM_SWAPPED,
          payload: response.data,
          oldCartId: cartId,
          suggestion,
          quantity
        })
        callback()
        //window.alertify.success('Item swapped successfully')
      })
      .catch((e) => {
        console.error(e)
        //window.alertify.error(e.response.data.message)
      })
  }
}

export const startFetchingCart = (history) => {
  return async (dispatch) => {
    await dispatch({
      type: FETCHING_CART
    })
    history.push('/cart')
  }
}

export const loadCarts = (sortBy, withLoading = true, callback) => {
  return (dispatch) => {
    // We only dispatch FETCHING_CART if this is an initial cart load.
    //
    // withLoading will be false when reloading the cart after a sort selection,
    // since we want to keep the loaded cart items in view and not show a loading
    // indicator in this case.
    if (withLoading) {
      dispatch({
        type: FETCHING_CART
      })
    }

    return api
      .get(apiUrls.cart.getCart, {
        params: {
          sort_by: sortBy
        }
      })
      .then((response) => {
        dispatch({
          type: RECEIVED_CART,
          payload: response.data
        })
        callback()
      })
      .catch((e) => {
        dispatch({
          type: FETCHING_CART_FAILURE
        })
        console.error(e)
        //window.alertify.error(e.response.data.message)
        callback()
      })
  }
}

export const loadSingleCart = (cartId, withLoading = true, callback) => {
  return (dispatch) => {
    if (withLoading) {
      dispatch({
        type: FETCHING_CART
      })
    }

    return api
      .get(apiUrls.cart.getCartDetails(cartId), {})
      .then((response) => {
        dispatch({
          type: RECEIVED_CART,
          payload: response.data,
          singleCart: true
        })
        callback()
      })
      .catch((e) => {
        console.log(e)
        dispatch({
          type: FETCHING_CART_FAILURE
        })
        console.error(e)
        //window.alertify.error(e.response.data.message)
        callback()
      })
  }
}

export const resetLoadingItems = (cartId) => {
  return (dispatch) => {
    dispatch({
      type: RESET_LOADING_ITEMS,
      cartId: cartId
    })
  }
}

export const loadLineItems = (
  page,
  cartId,
  sortBy,
  showLoading = true,
  reset = false
) => {
  return (dispatch) => {
    if (showLoading) {
      dispatch({
        type: LOADING_ITEMS,
        cartId: cartId
      })
    }
    return api
      .get(apiUrls.cart.getCartLineItems(cartId), {
        params: {
          page: page,
          sort_by: sortBy
        }
      })
      .then((response) => {
        dispatch({
          type: LOADED_ITEMS,
          payload: response.data.line_items,
          cartId: cartId,
          hasMore: response.data.total_pages > page,
          reset: reset,
          showLoading: showLoading
        })
      })
      .catch((e) => {
        dispatch({
          type: LOADING_ITEMS_FAILURE,
          cartId: cartId
        })
        console.error(e)
        //window.alertify.error(e.response.data.message)
      })
  }
}

export const addToCart = (
  supplierId,
  variantId,
  parentProductId,
  quantity,
  cartId
) => {
  return (dispatch) => {
    dispatch({
      type: ADDING_PRODUCT,
      variantId
    })

    plausible.trackEvent(events.pharmacy.addToCart, {
      variantId,
      parentProductId,
      quantity
    })

    return api
      .post(apiUrls.cart.addToCart(supplierId), {
        variant_id: variantId,
        parent_product_id: parentProductId,
        quantity
      })
      .then((response) => {
        dispatch({
          type: ADDED_TO_CART,
          newItemQty: quantity,
          payload: response.data,
          variantId,
          cartId
        })
      })
      .catch((e) => {
        dispatch({
          type: ADD_TO_CART_FAILURE
        })
        console.error(e)
        e && alert(e.response.data.message)
      })
  }
}

export const updateQty = (supplierId, lineItem, cartId, updatedQty, oldQty) => {
  return (dispatch) => {
    dispatch({
      type: UPDATING_QTY,
      lineItem: lineItem,
      cartId: cartId,
      updatedQty: updatedQty,
      oldQty: oldQty
    })

    return api
      .patch(apiUrls.cart.updateLineItem(supplierId, lineItem.id), {
        quantity: updatedQty
      })
      .then((_response) => {})
      .catch((e) => {
        console.error(e)
        dispatch({
          type: UPDATING_QTY_FAILURE,
          lineItem: lineItem,
          cartId: cartId,
          updatedQty: updatedQty,
          oldQty: oldQty
        })
        alert(e.response.data.message)
      })
  }
}

// Re-adds a line item to the cart that was previously unavailable
// but became available once again
export const readdLineItem = (supplierId, lineItem) => {
  return (dispatch) => {
    dispatch({
      type: READDING_LINE_ITEM,
      lineItem: lineItem,
      cartId: lineItem.order_id,
      updatedQty: lineItem.desired_quantity,
      oldQty: 0
    })
    return api
      .patch(apiUrls.cart.updateLineItem(supplierId, lineItem.id), {
        quantity: lineItem.desired_quantity,
        soft_deleted: false
      })
      .then((response) => {
        const softDeleted = response.data.soft_deleted
        if (softDeleted) {
          dispatch({
            type: READDING_LINE_ITEM_FAILURE,
            lineItem: lineItem,
            cartId: lineItem.order_id,
            updatedQty: lineItem.desired_quantity,
            oldQty: 0
          })
        }
      })
      .catch((e) => {
        console.error(e)
        dispatch({
          type: READDING_LINE_ITEM_FAILURE,
          lineItem: lineItem,
          cartId: lineItem.order_id,
          updatedQty: lineItem.desired_quantity,
          oldQty: 0
        })
        //window.alertify.error(e.response.data.message)
      })
  }
}

export const removeLineItem = (supplierId, lineItem, cartId, updatedQty) => {
  return (dispatch) => {
    dispatch({
      type: REMOVING_LINE_ITEM,
      lineItem: lineItem,
      cartId: cartId,
      updatedQty: updatedQty
    })

    return api
      .delete(apiUrls.cart.removeLineItem(supplierId, lineItem.id))
      .then((response) => {
        dispatch({
          type: REMOVED_LINE_ITEM,
          lineItemsCount: response.data.line_items_count,
          itemTotal: response.data.item_total,
          itemCount: response.data.item_count,
          cartId: cartId
        })
        //window.alertify.success(I18n.t('cart.removed_from_cart'))
      })
      .catch((e) => {
        dispatch({
          type: REMOVE_LINE_ITEM_FAILURE,
          lineItem: lineItem,
          cartId: cartId,
          updatedQty: updatedQty
        })

        console.error(e)
        //window.alertify.error(e.response.data.message)
      })
  }
}

export const saveLineItem = (supplierId, lineItem, cartId, qty) => {
  return (dispatch) => {
    dispatch({
      type: REMOVING_LINE_ITEM,
      lineItem: lineItem,
      cartId: cartId,
      qty: qty
    })

    return api
      .patch(apiUrls.cart.saveLineItem(supplierId, lineItem.id), {})
      .then((_response) => {})
      .catch((e) => {
        console.error(e)
        dispatch({
          type: REMOVE_LINE_ITEM_FAILURE,
          lineItem: lineItem,
          cartId: lineItem.order_id,
          qty: lineItem.desired_quantity
        })
        //window.alertify.error(e.response.data.message)
      })
  }
}

export const updateRequiredStatus = (lineItem, cartId) => {
  return (dispatch) => {
    dispatch({
      type: UPDATING_LINE_ITEM_REQUIRED,
      lineItem: lineItem,
      newStatus: !lineItem.required
    })

    return api
      .patch(apiUrls.cart.requiredLineItem(cartId, lineItem.id), {})
      .then((_response) => {})
      .catch((e) => {
        dispatch({
          type: UPDATING_LINE_ITEM_REQUIRED_FAILURE,
          cartId: cartId,
          oldStatus: lineItem.required
        })
        console.error(e)
      })
  }
}

export const setQty = (variantId, qty) => {
  return (dispatch) => {
    dispatch({
      type: CHANGE_QTY,
      qty: qty,
      variantId: variantId
    })
  }
}

export function setCartsTotal(cartsTotal, totalItemsCount) {
  return (dispatch) => {
    dispatch({
      type: SET_CARTS_TOTAL,
      cartsTotal: cartsTotal,
      totalItemsCount: totalItemsCount
    })
  }
}

export const checkWebServiceStatus = (supplierId) => {
  return (dispatch) => {
    return api
      .get(apiUrls.other.getWebService, {
        params: {
          supplier_id: supplierId
        }
      })
      .then((response) => {
        dispatch({
          type: WEB_SERVICE_RECEIVED_SERVICE_STATUS,
          externalWebServiceAvailable: response.data.status === 'OK'
        })
      })
      .catch((e) => {
        console.error(e)
      })
  }
}

export const checkWebServiceForProductInfo = (orderId) => {
  return (dispatch) => {
    return api
      .get(apiUrls.other.getWebServiceProductInfo, {
        params: {
          order_id: orderId
        }
      })
      .then((response) => {
        if (response.data.cart_updated) {
          dispatch({
            type: REFRESH_LIVE_UPDATES,
            cartId: orderId,
            payload: response.data
          })
          // window.alertify.success(
          //   I18n.t('cart.web_service_items_updated', {
          //     wholesalerName: response.data.supplier_name
          //   })
          // )
        }
      })
      .catch((e) => {
        console.error(e)
      })
  }
}
