export const initSentry = async () => {
  const Sentry = await import('@sentry/react')
  const { BrowserTracing } = await import('@sentry/tracing')
  const { createBrowserHistory } = await import('history')

  const history = createBrowserHistory()

  Sentry.init({
    dsn: process.env.RAZZLE_SENTRY_DSN_KEY,
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV5Instrumentation(history)
      })
    ],
    environment: process.env.RAZZLE_APP_ENV,
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0
  })
}
